
  import { Component } from 'vue-property-decorator'
  import { Linked } from '.'

@Component
  export default class Record extends Linked {
  declare properties: {
    itemText?: string
    itemValue?: string
    clearable?: boolean
    icon: {
      create?: string
      edit?: string
    };
  };

  get bind () {
    const { properties, title, icon, isCreated } = this

    return {
      readonly: true,
      ...properties,
      value: title,
      appendOuterIcon: icon,
      clearable: !isCreated && properties.clearable,
    }
  }
  }
